
////////////////////////////////////////////////////////////////////////////////////////////////
// DIALOGS
////////////////////////////////////////////////////////////////////////////////////////////////
let closeBtns = document.querySelectorAll('dialog .close')
if (closeBtns) {
  closeBtns.forEach(btn => {
    btn.addEventListener('click', handleBtnClose)
  })
  function handleBtnClose(e) {
    document.querySelector('dialog[open]').close()
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////
// ТЕСТ ПЕЧЕНИ
////////////////////////////////////////////////////////////////////////////////////////////////
let testCalls = Array.from(document.querySelectorAll('[data-test-call]'))
if (testCalls) {
  let testContainer = document.querySelector('.test-container')
  let testResultsItems = testContainer.querySelectorAll('.test-results__item')
  hideTestResultsItems()
  function hideTestResultsItems() {
    testResultsItems.forEach(item => item.classList.add('hidden'))
  }
  let ww, wh, ratio
  getWindowSizes()
  function getWindowSizes() {
    ww = window.innerWidth
    wh = window.innerHeight
    ratio = Number((ww / wh).toFixed(4))
    testContainer.style.setProperty('--ratio', ratio)
  }
  let dialogTest = document.querySelector('.dialog--test')
  let closeBtn = dialogTest.querySelector('.close')
  closeBtn.addEventListener('click', handleCloseBtn)
  function handleCloseBtn() {
    clearInterval(interval_id)
    removeCirclesEventListeners()
  }
  dialogTest.addEventListener("click", (e) => {
    const dialogDimensions = dialogTest.getBoundingClientRect()
    if (
      e.clientX < dialogDimensions.left ||
      e.clientX > dialogDimensions.right ||
      e.clientY < dialogDimensions.top ||
      e.clientY > dialogDimensions.bottom
    ) {
      clearInterval(interval_id)
      removeCirclesEventListeners()
      dialogTest.close()
    }
  })

  testCalls.forEach(btn => {
    btn.addEventListener('click', () => {
      let dialogOpen = document.querySelector('dialog[open]')
      if (dialogOpen) {
        let iframe = dialogOpen.querySelector('[data-iframe-video]')
        if (iframe) {
          iframe.removeAttribute('src')
        }
        if(dialogOpen) dialogOpen.close()
      }
      dialogTest.showModal()
      handleBtnStart()
    })
  })

  // ===============================

  let test = document.querySelector('.test')
  let timer = document.querySelector('.test-container__timer')
  let cont_w = test.offsetWidth
  let cont_h = test.offsetHeight
  const NUMBER = 25
  let arr_numbers = []
  for(let n=1; n<=NUMBER; n++){
    arr_numbers.push(n)
  }
  let new_arr_numbers = shuffle(arr_numbers)
  let boxes, circles, interval_id, time_counter = 0, number_counter = 0

  init()

  function startTimer() {
    if (interval_id) { clearInterval(interval_id) }
    hideTestResultsItems() // прячем текст с результатами текста
    time_counter = 0 // хранит время прошедшее с начала теста
    number_counter = 0 // хранит число кликов на числах
    timer.innerText = time_counter
    interval_id =  setInterval(()=>{
      time_counter++
      timer.innerText = time_counter
      if (time_counter === 40) {
        clearInterval(interval_id)
        removeCirclesEventListeners()
        showTestResults()
      }
    },1000)
  }

  function removeCirclesEventListeners(){
    circles.forEach(circle => {
      circle.removeEventListener('click', handleCircleClick)
    })
  }

  function showTestResults() {
    if (number_counter === 25) {
      testResultsItems[0].classList.remove('hidden')
    }
    if (number_counter >= 21 && number_counter < 25) {
      testResultsItems[1].classList.remove('hidden')
    }
    if (number_counter < 21) {
      testResultsItems[2].classList.remove('hidden')
    }
    removeCirclesEventListeners()
  }

  function handleBtnStart(){
    let lines = document.querySelectorAll('.line')
    if(lines){
      lines.forEach(line => line.remove())
    }
    boxes.forEach(box => box.remove())
    cont_w = test.offsetWidth
    cont_h = test.offsetHeight
    new_arr_numbers = shuffle(arr_numbers)
    init()
    startTimer()
  }

  function createBoxes(){
    let box_w = Math.floor(cont_w / 5)
    let box_h = Math.floor(cont_h / 5)
    let j = 0
    let start = false
    for(let i=0; i<NUMBER; i++){
      let box = document.createElement('div')
      box.classList.add('box')
      box.style.width = `${box_w}px`
      box.style.height = `${box_h}px`
      test.appendChild(box)
      let x = (i % 5) * Math.floor(cont_w / 5)
      if(start && i % 5 === 0) {
        j = j + 1
      }
      let y = j * Math.floor(cont_h / 5)
      box.style.transform = `translate(${x}px,${y}px)`
      // создаем круги
      createCircle(new_arr_numbers[i], box_w, box_h, box)
      start = true
    }
    boxes = document.querySelectorAll('.box')
    circles = document.querySelectorAll('.circle')
  }

  function createCircle(num, parent_w, parent_h, parent){
      let circle = document.createElement('div')
      circle.classList.add('circle')
      let span = document.createElement('span')
      let textNode = document.createTextNode(num)
      span.classList.add('num')
      span.appendChild(textNode)
      let s = parent_w < parent_h ? parent_w : parent_h
      s = s * .5
      circle.style.width = s + 'px'
      circle.style.height = s + 'px'
      let x = Math.random() * (parent_w - s)
      let y = Math.random() * (parent_h - s)
      circle.style.transform = `translate(${x}px, ${y}px)`
      circle.appendChild(span)
      if(circle.innerText === '1'){
        let span2 = document.createElement('span')
        let textNode2 = document.createTextNode('Старт')
        span2.classList.add('hint')
        span2.appendChild(textNode2)
        circle.appendChild(span2)
      }
      if(circle.innerText === '25'){
        let span2 = document.createElement('span')
        let textNode2 = document.createTextNode('Финиш')
        span2.classList.add('hint')
        span2.appendChild(textNode2)
        circle.appendChild(span2)
      }
      parent.appendChild(circle)
      circle.addEventListener('click', handleCircleClick)
  }

  function createLine(target, target_prev){
    let cont_left = Math.floor(test.getBoundingClientRect().left)
    let cont_top = Math.floor(test.getBoundingClientRect().top)
    let coord_1 = {
      x: Math.floor(target.getBoundingClientRect().left) - cont_left,
      y: Math.floor(target.getBoundingClientRect().top) - cont_top
    }
    let coord_2 = {
      x: Math.floor(target_prev.getBoundingClientRect().left) - cont_left,
      y: Math.floor(target_prev.getBoundingClientRect().top) - cont_top
    }
    let line = document.createElement('div')
    line.classList.add('line')
    let w = Math.abs(coord_1.x - coord_2.x)
    let h = Math.abs(coord_1.y - coord_2.y)
    w = Math.floor(Math.sqrt(w*w + h*h))
    line.style.width = `${w}px`
    let offset = document.querySelector('.circle').offsetWidth / 2
    let x = coord_1.x + offset
    let y = coord_1.y + offset
    let deg = Math.round( Math.atan( ( coord_2.y - coord_1.y ) / ( coord_2.x - coord_1.x ) ) * ( 180 / Math.PI ) )
    // deg = 0
    if(coord_1.x > coord_2.x){
      deg += 180
    }
    line.style.transform = `translate(${x}px, ${y}px) rotate(${deg}deg)`
    test.prepend(line)
  }

  function handleCircleClick(e){
    let target = e.currentTarget
    let target_prev
    if(target.classList.contains('active')) return
    if(target.querySelector('.num').innerText === '1' && !target.classList.contains('active')){
      target.classList.add('active')
      number_counter++
      return
    }
    let text = target.querySelector('.num').innerText
    circles.forEach(circle => {
      if(parseInt(circle.querySelector('.num').innerText) + 1 === parseInt(text) && circle.classList.contains('active')){
        target.classList.add('active')
        target_prev = circle
        createLine(target, target_prev)
        number_counter++
        if (parseInt(text) === NUMBER) {
          clearInterval(interval_id)
          removeCirclesEventListeners()
          showTestResults()
        }
      }
    })
  }

  function init(){
    createBoxes()
  }

  window.addEventListener('resize', () => {
    getWindowSizes()
    let lines = document.querySelectorAll('.line')
    lines.forEach(line => line.remove())
    removeCirclesEventListeners()
    boxes.forEach(box => box.remove())
    cont_w = test.offsetWidth
    cont_h = test.offsetHeight
    if(interval_id){clearInterval(interval_id)}
    time_counter = 0
    timer.innerText = time_counter
    new_arr_numbers = shuffle(arr_numbers)
    init()
    startTimer()
  })

  // helpers
  function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }
}
////////////////////////////////////////////////////////////////////////////////////////////////
// DIALOG - ВЫПОЛНИТЬ ТЕСТ ПОЗДНЕЕ
////////////////////////////////////////////////////////////////////////////////////////////////
let btnTestLaterCall = document.querySelector('[data-test-later-call]')
if (btnTestLaterCall) {
  let dialog = document.querySelector('.dialog--test-later')
  btnTestLaterCall.addEventListener('click', handleBtnTestLater)

  function handleBtnTestLater() {
    dialog.showModal()
  }

  dialog.addEventListener("click", (e) => {
    const dialogDimensions = dialog.getBoundingClientRect()
    if (
      e.clientX < dialogDimensions.left ||
      e.clientX > dialogDimensions.right ||
      e.clientY < dialogDimensions.top ||
      e.clientY > dialogDimensions.bottom
    ) {
      dialog.close()
    }
  })
}

////////////////////////////////////////////////////////////////////////////////////////////////
// ВИДЕО ДЛЯ ГЛАВНОЙ
////////////////////////////////////////////////////////////////////////////////////////////////
let priznakiCards = document.querySelectorAll('div.priznaki__card')
if (priznakiCards) {
  priznakiCards.forEach((card, index) => {
    card.setAttribute('data-card-index', index)
    card.addEventListener('click', handleCardClick)
  })

  let dialog = document.querySelector('.dialog--card-video')
  let iframe = dialog.querySelector('[data-iframe-video]')
  let btnDialogGoToTest = dialog.querySelector('[data-gototest]')
  let close = dialog.querySelector('.close')

  function handleCardClick(e) {
    let target = e.currentTarget
    let index = parseInt(target.getAttribute('data-card-index'))
    iframe.setAttribute('src', VIDEO[index].src)
    dialog.showModal()
  }

  close.addEventListener('click', handleBtnCloseClick)
  btnDialogGoToTest.addEventListener('click', handleBtnGoToTestClick)

  function handleBtnCloseClick() {
    iframe.removeAttribute('src')
  }

  function handleBtnGoToTestClick() {
    iframe.removeAttribute('src')
    dialog.close()
  }

  dialog.addEventListener("click", (e) => {
    const dialogDimensions = dialog.getBoundingClientRect()
    if (
      e.clientX < dialogDimensions.left ||
      e.clientX > dialogDimensions.right ||
      e.clientY < dialogDimensions.top ||
      e.clientY > dialogDimensions.bottom
    ) {
      iframe.removeAttribute('src')
      dialog.close()
    }
  })
}

